// Auth
export const LOGIN_URL = '/auth/login';
export const MAIN_USER_URL = '/user'

// User List
export const COMPAIGN_LIST_URL = '/campaign/list'
export const COMPAIGN_DELETE_URL = '/campaign/delete'

export const COMPAIGN_BY_ID = '/campaign/get-by-id'

export const COMPAIGN_COMMENT = '/campaign/comment'
// Update Status Compaign 
export const UPDATE_PRIORITY = '/campaign/update-priority'
export const UPDATE_STATUS = '/campaign/status'
export const WARNIN_MESSAGE = '/campaign/update-warnning'
export const DOWNLOAD_PDF = '/campaign/download-pdf'

// Reset Password Admin
export const CHECK_TOKEN_SENDEMIAL = '/user/admin/token-used'
export const RESET_PASSWORD = '/user/admin/reset-password'
