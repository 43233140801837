import React, { useState } from 'react'

const FieldComment = (props) => {

    const { keyId, title, fieldValue, callBackFunction } = props
    const [value, setValue] = useState(fieldValue)
    const [editField, setEditField] = useState(false)

    const handleSaveValue = () => {
        setEditField(!editField)
        if (editField) {
            callBackFunction(keyId, value)
            setEditField(false)
        }
    }

    const handleChangeValue = (event) => {
        setValue(event.target.value)
    }

    return (
        <div className='comment_form'>
            <div className="d-flex flex-row align-items-center justify-content-between" style={{ marginBottom: '4px' }}>
                <label className="size18700">{title}</label>
                <div className='d-flex flex-row align-items-center'>
                    {editField && <a href 
                        onClick={() => { 
                            setValue(`${fieldValue}`)
                            setEditField(false)
                    }} 
                    className="size14700 editColor" 
                    style={{ textDecoration: 'none', marginRight: '16px' }}>Cancel</a>}
                    <a href onClick={() => handleSaveValue()} className="size14700 editColor" style={{ textDecoration: 'none' }}>{`${editField ? "Save" : "Edit"}`}</a>
                </div>
            </div>
            <textarea onChange={handleChangeValue} disabled={editField ? false : true} value={value} class={`form-control ? 'is-invalid' : ''}`} id="exampleFormControlTextarea1" rows="8"></textarea>
            <div className="invalid-feedback"></div>
        </div>
    )
}

export default FieldComment