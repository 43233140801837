import React, { useState, useEffect, useRef } from "react";
import API from "../utils/request";
import { COMPAIGN_DELETE_URL, DOWNLOAD_PDF, UPDATE_PRIORITY, UPDATE_STATUS } from "../api/urls";
import { Link } from "react-router-dom";
import iconEdit from "../assets/images/iconEdit.svg";
import { FeedESTDateToString } from "../functions/format/date";
import Pagination from "../containers/pagination";
import { BiSortDown, BiSortUp } from 'react-icons/bi'
import { BsExclamationLg } from 'react-icons/bs'
import { bgCheck, progressStatus } from '../containers/FixedData/fixedData';
import iconWarning from "../assets/images/warning-icon.jpg"
import MessageModal from '../containers/modal/messageModal';
import MessageModalMain from "../containers/modal/messageModalMain";
import downloadjs from 'js-file-download';

const WebFormList = (props) => {
    const {
        setLoading,
        limitList,
        dataForm,
        fetchUserFormList,
        pagination,
        setPagination,
        setSortField,
        setSort,
        sort,
        sortField,
        totalCount,
    } = props;
    const messageModalRef = useRef('rememberMe');
    const [messageModal, setMessageModal] = useState(false)
    const [indexDelete, setIndexDelete] = useState(null)
    const [messageFetch, setMessageFetch] = useState("")
    const [item, setItem] = React.useState("")
    const [id, setId] = React.useState("")

    const [sortFields, setSortFields] = useState([
        {
            name: "Submission Date",
            sort: 1,
            sortField: "_id",

        },

        {
            name: "Name",
            sort: 1,
            sortField: "firstName",

        },
        {
            name: "Email",
            sort: 1,
            sortField: "email",

        },
        {
            name: "Campaign Title",
            sort: 1,
            sortField: "title",

        },
        // {
        //     name: "Assignee",
        //     sort: 1,
        //     sortField: "assignee"

        // },
        {
            name: "Progress",
            sort: 1,
            sortField: "status"

        },
    ]);

    const updateStatusCompaing = (event, item) => {
        API.post(UPDATE_STATUS, {
            campaignId: item._id,
            status: event.target.value,
        })
            .then((res) => {
                if (res.status === 1) {
                    fetchUserFormList();
                } else {
                    alert(res.message);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    // Sort by ascending & descending for stakeholder
    const handleSort = (item, index) => {

        setSort(item.sort)
        if (item.sort === 1) {
            sortFields[index].sort = -1;
        } else {
            sortFields[index].sort = 1
        }
        setSortField(item.sortField);
        setSortFields([...sortFields])
    }

    // change icon when sort ascending && descending
    const changeIcon = (type) => {
        switch (type) {
            case 1:
                return <BiSortDown />;
            case -1:
                return <BiSortUp />;
            default:
                return null;
        }
    };
    // update-priority
    const updatePriority = (id, isPriority) => {
        API.post(UPDATE_PRIORITY, {
            campaignId: id,
            isPriority: isPriority === 1 ? 0 : 1
        }).then((res) => {
            if (res.status === 1) {
                fetchUserFormList();
            }
            return false;
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteUser = (e) => {
        API.post(COMPAIGN_DELETE_URL, {
            "campaignId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess("The intake webform has been successfully deleted.");
                setMessageModal(false)
                fetchUserFormList()
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }
    const deleteCampaginMessage = (item, index) => {
        setIndexDelete(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageModal(true)
        setId(item._id)

    };

    const downloadPDF = (item) => {
        API.post(DOWNLOAD_PDF, {
            "campaignId": item._id,
        }, { responseType: 'blob' }).then((res) => {
            downloadjs(res, `CampaignIntakeForm_${item.firstName + ' ' + item.lastName}_${item.title}.pdf`);
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        })
    }

    function split(item) {
        var avg, splitted, part1, part2;
        splitted = item.split("@");
        part1 = splitted[0];
        avg = part1.length / 2;
        part1 = part1.substring(0, (part1.length - avg));
        part2 = splitted[1];
        return part1 + "...@" + part2;
    }


    useEffect(() => {
        fetchUserFormList()
    }, [sortField, sort]);

    return (
        <div className="col-md-12">
            <div className="table-responsive" style={{ minHeight: '200px' }}>
                <table className="table">
                    <thead className="thead-primary">
                        <tr>
                            <th>No.</th>
                            {
                                sortFields.map((field, i) => {
                                    return <th key={i} onClick={() => handleSort(field, i)}>{field.name} {changeIcon(field.sort)}</th>
                                })
                            }
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataForm.map((value, index) => (
                            <tr>
                                <td className="td_radiusLeft">
                                    {index + 1}
                                </td>
                                <td>
                                    <p>{FeedESTDateToString(value.submitDate)}</p>
                                </td>
                                <td>{`${value.firstName} ${value.lastName}`}</td>
                                <td data-toggle="tooltip" data-placement="top" title={value.email}>{split(value.email)}</td>
                                <td data-toggle="tooltip" data-placement="top" title={value.title}>{value.title && value.title.length > 15 ? value.title.slice(0, 15) + '...' : value.title}</td>
                                {/* <td>Assignee</td> */}
                                <td>
                                    <div className=" input_form" style={{ position: "relative" }}>
                                        <select
                                            id="inputState"
                                            className="selectpicker"
                                            onChange={(event) => updateStatusCompaing(event, value)}
                                            style={{
                                                width: "100%",
                                                height: "33px",
                                                border: "0px solid rgba(0, 0, 0, 0.15)",
                                                borderColor: "#ced4da",
                                                borderRadius: "0.25rem",
                                                color: "black",
                                                backgroundColor: bgCheck(value.status),
                                                cursor: "pointer",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {progressStatus.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.value}
                                                    selected={value.status === item.value}
                                                    style={{
                                                        marginBottom: "0",
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td className="td_radiusRight" style={{ display: 'flex' }}>
                                    <div className="dropdown">
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" style={{ fontSize: '14px', marginTop: '5px', marginLeft: '5px' }}>
                                            <img
                                                src={iconEdit}
                                                alt="iconEdit"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </div>
                                        <div className="dropdown-menu leftMenu" style={{ zIndex: 99 }}>
                                            <Link
                                                to={`/viewFormIntake?cam=${value._id}`}
                                                state={value}
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                View Form
                                            </Link>
                                            <Link
                                                to="#!"
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => deleteCampaginMessage(value)}
                                            >
                                                Delete Form
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => downloadPDF(value)}
                                            >
                                                Download Form PDF
                                            </Link>
                                            <Link
                                                state={value}
                                                onClick={() => updatePriority(value._id, value.isPriority)}
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {
                                                    value.isPriority === 1 ? 'Remove High Priority' : 'Set High Priority'
                                                }

                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        value.isPriority === 1 ? <BsExclamationLg style={{ width: '14px', color: "#FC1921", marginTop: '10px', marginLeft: '10px' }}></BsExclamationLg> : ""
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalCount >= 10 && (
                <Pagination
                    totalCount={totalCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    limitList={limitList}
                />
            )}
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
            />
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
                deleteUser={deleteUser}
                indexDelete={indexDelete}
            />
            <MessageModalMain textCentered ref={messageModalRef} />
        </div>

    );
};

export default WebFormList;
