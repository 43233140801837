import React, { Fragment } from 'react'
import DesktopHeader from './desktopHeader'
import MobileHeader from './mobileHeader'

const Layout = (props) => {
  return (
    <Fragment>
      <DesktopHeader/>
      <MobileHeader />
      <div className='container'>
        {props.children}
      </div>
    </Fragment>
  )
}

export default Layout