import React, { useState, useEffect, useRef } from 'react';
import '../../styles/mangementUser.css'
import '../../styles/pagination.css'
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import iconEdit from '../../assets/images/iconEdit.svg';
import AddStakeholderModal from '../../containers/modal/addStakeholderModal';
import AddAdminModal from '../../containers/modal/addAdminModal';
import { BiSearch } from 'react-icons/bi';
import MessageModal from '../../containers/modal/messageModal';
import iconWarning from "../../assets/images/warning-icon.jpg"
import { MAIN_USER_URL } from '../../api/urls';
import MessageModalMain from "../../containers/modal/messageModalMain";
import API from '../../utils/request'
import moment from 'moment';
import Pagination from 'react-responsive-pagination';
import { BiSortDown, BiSortUp } from 'react-icons/bi'
import LoadingOverlay from '../../containers/loading/loadingOverlay';
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';
import MessageResetPassword from '../../containers/modal/messageResetPassword';

var username = Storage.getJson(storageKeys.USER);
const ManageUsers = () => {
    const [active, setActive] = useState("stakeholder")
    const [stakeholderModal, setStakeholderModal] = useState(false)
    const [stakeholderFetch, setStakeholderFetch] = useState("")
    const [stakeholder, setStakeholder] = React.useState([]);
    const [indexDelete, setIndexDelete] = useState(null)
    const [indexReset, setIndexReset] = useState(null)
    const [adminModal, setAdminModal] = useState(false)
    const [messageResetModal, setMessageResetModal] = useState(false)
    const [adminFetch, setAdminFetch] = useState("")
    const [messageModal, setMessageModal] = useState(false)
    const [messageFetch, setMessageFetch] = useState("")
    const [keywordSearch, setKeywordSearch] = React.useState("")
    const [item, setItem] = React.useState("")
    const [id, setId] = React.useState("")
    const [itemCount, setItemCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const messageModalRef = useRef('rememberMe');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState("_id");
    const [sort, setSort] = useState("desc");
    const [sortFields, setSortFields] = useState([
        {
            name: "UserName",
            sort: "asc",
            sortField: "userName",

        },

        {
            name: "First Name",
            sort: "asc",
            sortField: "firstName",

        },
        {
            name: "Last Name",
            sort: "asc",
            sortField: "lastName",

        },
        {
            name: "Email",
            sort: "asc",
            sortField: "primaryEmail",

        },
        {
            name: "Department",
            sort: "asc",
            sortField: "department"

        },
    ]);

    const [sortFieldsAdmin, setSortFieldsAdmin] = useState([
        {
            name: "UserName",
            sort: "asc",
            sortField: "userName",

        },

        {
            name: "First Name",
            sort: "asc",
            sortField: "firstName",

        },
        {
            name: "Last Name",
            sort: "asc",
            sortField: "lastName",

        },
        {
            name: "Email",
            sort: "asc",
            sortField: "primaryEmail",

        },
        {
            name: "User Last Access",
            sort: "asc",
            sortField: "_id"

        },
    ]);
    const openStakeholderModal = (e) => {
        e.preventDefault()
        setStakeholderFetch("Open")
        setStakeholderModal(true)
    }

    const openAdminModal = (e) => {
        e.preventDefault()
        setAdminFetch("Open")
        setAdminModal(true)
    }
    // list show manage users
    const getStakeholder = (type) => {
        setLoading(true)
        API.post(MAIN_USER_URL + '/admin/list', {
            searchParams: {
                roles: active,
                userName: keywordSearch
            },
            sortField: sortField,
            sort: sort,
            limit: 10,
            page: keywordSearch ? 1 : page

        }).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                setStakeholder(res.data)
                let totalRecord = res.totalCount ? Math.ceil(res.totalCount / 10) : 0
                setItemCount(totalRecord)
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            console.log(err)
            return false;
        })
    }

    // send reset password message
    const sendResetPasswordLink = () => {
        API.post(MAIN_USER_URL + "/admin/send-reset-password", {
            "userId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess(res.message);
                setMessageModal(false)
                getStakeholder();
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }
    const ResetStakeholder = (item, index) => {
        setIndexReset(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageResetModal(true)
        setId(item._id)

    };

    // delete manage users
    const deleteUser = (e) => {
        API.post(MAIN_USER_URL + "/admin/delete", {
            "userId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess("The user has been successfully deleted.");
                setMessageModal(false)
                getStakeholder();
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }
    const deleteStakeholder = (item, index) => {
        setIndexDelete(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageModal(true)
        setId(item._id)

    };

    // Sort by ascending & descending for stakeholder
    const handleSort = (item, index) => {

        setSort(item.sort)
        if (item.sort === 'asc') {
            sortFields[index].sort = 'desc';
        } else {
            sortFields[index].sort = 'asc'
        }
        setSortField(item.sortField);
        setSortFields([...sortFields])
    }

    // Sort by ascending & descending for admin
    const handleSortAdmin = (item, index) => {

        setSort(item.sort)
        if (item.sort === 'asc') {
            sortFieldsAdmin[index].sort = 'desc';
        } else {
            sortFieldsAdmin[index].sort = 'asc'
        }
        setSortField(item.sortField);
        setSortFieldsAdmin([...sortFieldsAdmin])
    }

    // change icon when sort ascending && descending
    const changeIcon = (type) => {
        switch (type) {
            case "asc":
                return <BiSortDown />;
            case "desc":
                return <BiSortUp />;
            default:
                return null;
        }
    };

    useEffect(() => {
        getStakeholder(active)
    }, [page, active, sortField, sort]);

    function handlePageChange(page) {
        setPage(page);
        setCurrentPage(page)
    }

    function split(item) {
        var avg, splitted, part1, part2;
        splitted = item.split("@");
        part1 = splitted[0];
        avg = part1.length / 2;
        part1 = part1.substring(0, (part1.length - avg));
        part2 = splitted[1];
        return part1 + "...@" + part2;
    }

    const handleSearchUsers = () => {
        getStakeholder();
    }
    const handleKeyEnter = (e) => {
        if (e.key === 'Enter') {
            handleSearchUsers()
        }
    };
    return (
        <div>
            <div className="container" style={{ marginTop: '120px' }}>
                <h1 className="passwordH3">Manage Users</h1>
                <div className="">
                    <div className='row' style={{ marginTop: '40px' }}>
                        <div className='col-12'>
                            <div className='d-flex flex-row'>
                                <div onClick={() => {
                                    setActive('stakeholder')
                                    setKeywordSearch('')
                                    setCurrentPage('')
                                    setPage('')
                                }} className={`d-flex flex-column align-items-center ${active === 'stakeholder' ? "underline" : 'noneActive'}`}>
                                    <p className="bold_desc" style={{ marginBottom: '12px' }}>Stakeholder</p>

                                </div>
                                <div onClick={() => {
                                    setActive('admin')
                                    setKeywordSearch('')
                                    setCurrentPage('')
                                    setPage('')
                                }} className={`d-flex flex-column align-items-center ${active === 'admin' ? "underline" : 'noneActive'}`}>
                                    <p className="bold_desc" style={{ marginBottom: '12px' }}>Admin</p>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '1px', backgroundColor: '#E2DFDA' }} />
                        </div>
                        <div className='col-12' style={{ marginTop: '24px' }}>
                            <div style={{ width: '300px' }}>
                            </div>
                        </div>
                        {(() => {
                            switch (active) {
                                // tab stakeholder
                                case 'stakeholder':
                                    return (
                                        <div className=''>
                                            <div className='row' style={{ paddingRight: '12px' }}>
                                                <div className="col-7 col-md-7 col-sm-7 mt-md-0 mt-3">
                                                    <input
                                                        style={{ height: '50px' }}
                                                        className="form-control text-dark infoInput"
                                                        placeholder="Search UserName"
                                                        type="text"
                                                        value={keywordSearch}
                                                        onKeyDown={handleKeyEnter}
                                                        onChange={(e) => {
                                                            setKeywordSearch(e.target.value)
                                                        }}
                                                    />
                                                    <BiSearch onClick={() => handleSearchUsers()} size={20} style={{ float: 'right', marginTop: '-33px', marginRight: '10px', cursor: 'pointer' }} />
                                                </div>
                                                <div className="col-5 col-md-5 col-sm-5 mt-md-0  mt-3">
                                                    <div className="row text-center addNew" onClick={(e) => openStakeholderModal(e)}>
                                                        <div className="col-12 col-md-2 col-sm-12">
                                                            <AiOutlinePlus size={20} />
                                                        </div>
                                                        <div className="col-12 col-md-10 col-sm-12">
                                                            <p style={{ fontSize: '16px', fontWeight: 700 }}>Add User</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Table */}
                                            <div className="col-12 col-md-12 col-sm-12 mt-5">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-sm-12">
                                                        <div className="table-responsive" style={{minHeight:'200px'}}>
                                                            <table className="table">
                                                                <thead className="thead-primary">
                                                                    <tr>
                                                                        {
                                                                            sortFields.map((field, i) => {
                                                                                return <th key={i} onClick={() => handleSort(field, i)}>{field.name} {changeIcon(field.sort)}</th>
                                                                            })
                                                                        }
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    stakeholder.length > 0 ?
                                                                        <tbody>
                                                                            {stakeholder.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td className='td_radiusLeft' data-toggle="tooltip" data-placement="top" title={item.userName}>
                                                                                        {item.userName && item.userName.length > 15 ? item.userName.slice(0, 15) + '...' : item.userName}
                                                                                    </td>
                                                                                    <td className='text-capitalize' data-toggle="tooltip" data-placement="top" title={item.firstName}>
                                                                                        {item.firstName && item.firstName.length > 15 ? item.firstName.slice(0, 15) + '...' : item.firstName}
                                                                                    </td>
                                                                                    <td className='text-capitalize' data-toggle="tooltip" data-placement="top" title={item.lastName}>
                                                                                        {item.lastName && item.lastName.length > 15 ? item.lastName.slice(0, 15) + '...' : item.lastName}
                                                                                    </td>
                                                                                    <td data-toggle="tooltip" data-placement="top" title={item.primaryEmail}>{split(item.primaryEmail)}</td>
                                                                                    <td className='text-capitalize' data-toggle="tooltip" data-placement="top" title={item.department}>
                                                                                        {item.department && item.department.length > 15 ? item.department.slice(0, 15) + '...' : item.department}
                                                                                    </td>
                                                                                    <td className='td_radiusRight'>
                                                                                        <div className="dropdown">
                                                                                            <div className="dropdown-toggle" data-bs-toggle="dropdown">
                                                                                                <img src={iconEdit} alt="" style={{ width: '18px', height: '18px', cursor: 'pointer' }} />
                                                                                            </div>

                                                                                            <div className="dropdown-menu dropdownResetPassword" style={{ zIndex: 99 }}>
                                                                                                <Link
                                                                                                    state={item}
                                                                                                    className="dropdown-item"
                                                                                                    style={{
                                                                                                        color: 'black',
                                                                                                        textDecoration: 'none',
                                                                                                        fontSize: '14px'
                                                                                                    }}
                                                                                                    onClick={() => ResetStakeholder(item, index)}
                                                                                                >Send Password Reset</Link>
                                                                                                <Link
                                                                                                    className="dropdown-item"
                                                                                                    style={{
                                                                                                        color: 'black',
                                                                                                        textDecoration: 'none',
                                                                                                        fontSize: '14px'
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        deleteStakeholder(item, index)}
                                                                                                >Delete User</Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : <p className='col-12 col-md-12 col-sm-12 text-center' style={{ fontSize: '24px', fontWeight: 500 }}></p>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pagination */}
                                            {itemCount > 1 ?
                                                <div className='col-12 col-md-12 col-sm-12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Pagination
                                                        total={itemCount}
                                                        current={currentPage}
                                                        onPageChange={page => handlePageChange(page)}
                                                    />
                                                </div> : null
                                            }
                                        </div>
                                    )
                                // tab admin
                                case 'admin':
                                    return (
                                        <div className="">
                                            <div className='row' style={{ paddingRight: '12px' }}>
                                                <div className="col-7 col-md-7 col-sm-7 mt-md-0 mt-3">
                                                    <input
                                                        style={{ height: '50px' }}
                                                        className="form-control text-dark infoInput ms-n5"
                                                        placeholder="Search UserName"
                                                        type="text"
                                                        autoComplete='off'
                                                        value={keywordSearch}
                                                        onKeyDown={handleKeyEnter}
                                                        onChange={(e) => {
                                                            setKeywordSearch(e.target.value)
                                                        }}
                                                    />
                                                    <BiSearch onClick={() => handleSearchUsers()} size={20} style={{ float: 'right', marginTop: '-33px', marginRight: '10px', cursor: 'pointer' }} />
                                                </div>
                                                <div className="col-5 col-md-5 col-sm-5 mt-md-0 mt-3">
                                                    <div className="row text-center addNew" onClick={(e) => openAdminModal(e)}>
                                                        <div className="col-12 col-md-2 col-sm-12">
                                                            <AiOutlinePlus size={20} />
                                                        </div>
                                                        <div className="col-12 col-md-10 col-sm-12">
                                                            <p style={{ fontSize: '16px', fontWeight: 700 }}>Add User</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Table */}
                                            <div className="col-12 mt-5">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive" style={{minHeight:'200px'}}>
                                                            <table className="table">
                                                                <thead className="thead-primary">
                                                                    <tr>
                                                                        {
                                                                            sortFieldsAdmin.map((field, i) => {
                                                                                return <th key={i} onClick={() => handleSortAdmin(field, i)}>{field.name} {changeIcon(field.sort)}</th>
                                                                            })
                                                                        }
                                                                        <th>Action</th>
                                                                    </tr>

                                                                </thead>
                                                                {
                                                                    stakeholder.length > 0 ?
                                                                        <tbody>
                                                                            {stakeholder.map((itemAdmin, index) => (
                                                                                <tr key={index}>
                                                                                    <td className='td_radiusLeft' data-toggle="tooltip" data-placement="top" title={item.userName}>
                                                                                        {itemAdmin.userName.length > 15 ? itemAdmin.userName.slice(0, 15) + '...' : itemAdmin.userName}
                                                                                    </td>
                                                                                    <td className='text-capitalize' data-toggle="tooltip" data-placement="top" title={item.firstName}>
                                                                                        {itemAdmin.firstName.length > 15 ? itemAdmin.firstName.slice(0, 15) + '...' : itemAdmin.firstName}
                                                                                    </td>
                                                                                    <td className='text-capitalize' data-toggle="tooltip" data-placement="top" title={item.lastName}>
                                                                                        {itemAdmin.lastName.length > 15 ? itemAdmin.lastName.slice(0, 15) + '...' : itemAdmin.lastName}
                                                                                    </td>
                                                                                    <td data-toggle="tooltip" data-placement="top" title={item.primaryEmail}>{itemAdmin.primaryEmail}</td>
                                                                                    <td>{moment(itemAdmin.lastAccess).format("MM-DD-YYYY")}</td>
                                                                                    <td className='td_radiusRight'>
                                                                                        {
                                                                                            username === itemAdmin.userName ? <img src={iconEdit} alt="iconEdit" style={{ width: '18px', height: '18px', opacity: 0.2 }} /> : <div className="dropdown">
                                                                                                <div className="dropdown-toggle" data-bs-toggle="dropdown">
                                                                                                    <img src={iconEdit} alt="iconEdit" style={{ width: '18px', height: '18px', cursor: 'pointer' }} />
                                                                                                </div>
                                                                                                <div className="dropdown-menu dropdownResetPassword">
                                                                                                    <Link
                                                                                                        state={itemAdmin}
                                                                                                        className="dropdown-item"
                                                                                                        style={{
                                                                                                            color: 'black',
                                                                                                            textDecoration: 'none',
                                                                                                            fontSize: '14px'
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            ResetStakeholder(itemAdmin, index)}
                                                                                                    >Send Password Reset</Link>
                                                                                                    <Link
                                                                                                        className="dropdown-item"
                                                                                                        style={{
                                                                                                            color: 'black',
                                                                                                            textDecoration: 'none',
                                                                                                            fontSize: '14px'
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            deleteStakeholder(itemAdmin, index)}
                                                                                                    >Delete User</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : <p className='col-12 col-md-12 col-sm-12 text-center' style={{ fontSize: '24px', fontWeight: 500 }}></p>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {itemCount > 1 ?
                                                <div className='col-12 col-md-12 col-sm-12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Pagination
                                                        total={itemCount}
                                                        current={currentPage}
                                                        onPageChange={page => handlePageChange(page)}
                                                    />
                                                </div> : null
                                            }
                                        </div>
                                    )
                                default:
                                    return null
                            }
                        })()}
                    </div>
                </div>
                {/* search */}
            </div>
            <AddStakeholderModal
                showModal={stakeholderModal}
                setStakeholderModal={setStakeholderModal}
                setStakeholderFetch={setStakeholderFetch}
                getStakeholder={getStakeholder}
            />
            <AddAdminModal
                showModal={adminModal}
                setAdminModal={setAdminModal}
                setStakeholderFetch={setAdminFetch}
                getAdmin={getStakeholder}
            />
            <MessageModal
                item={item}
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this user?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
                setMessageFetch={setMessageFetch}
                indexDelete={indexDelete}
                deleteUser={deleteUser}

            />
            <MessageResetPassword
                item={item}
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to send reset password link?"
                showModal={messageResetModal}
                setMessageResetModal={setMessageResetModal}
                setMessageFetch={setMessageFetch}
                sendResetPasswordLink={sendResetPasswordLink}
                indexReset={indexReset}
            />
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
        </div>
    )
}

export default ManageUsers;