import React, { useRef, useState } from "react";
import { LOGIN_URL } from "../../api/urls";
import LoadingOverlay from "../../containers/loading/loadingOverlay";
import '../../styles/login.css'
import { Buffer } from 'buffer'
import API from '../../utils/request'
import MessageModalMain from "../../containers/modal/messageModalMain";
import { storageKeys } from "../../constants/storage";
import * as Storage from "../../utils/storage";
import { useEffect } from "react";


const LoginPage = () => {

    const [password, setPassword] = useState("");
    const [passwordInvalid, setPasswordInvalid] = useState("")
    const [usernameInvalid, setUsernameInvalid] = useState("")
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false)
    const messageModalRef = useRef('rememberMe');

    useEffect(() => {
        if (localStorage.getItem(storageKeys.TOKEN)) {
            window.location.href = "/";
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!username) {
            setUsernameInvalid("Please enter your username!")
            return false
        }
        if (!password) {
            setPasswordInvalid("Please enter your password!")
            return false
        }
        setLoading(true)
        API.post(LOGIN_URL, {
            "username": username,
            "password": Buffer.from(password).toString('base64')
        })
            .then((res) => {
                setLoading(false)
                if (res.status === 1) {
                    Storage.setString(storageKeys.TOKEN, res.token);
                    Storage.setJson(storageKeys.USER, res.data.userName);
                    Storage.setJson(storageKeys.FIRSTNAME, res.data.firstName);
                    Storage.setJson(storageKeys.LASTNAME, res.data.lastName);
                    window.location.href = "/";
                } else {
                    messageModalRef.current.showWarning(res.status.message);
                }
            })
            .catch((err) => {
                messageModalRef.current.showWarning(err.message);
                setLoading(false)
            })
    }
    if (localStorage.getItem(storageKeys.TOKEN)) {
        return null;
    }
    return (
        <div className="pt-5">
            <h1 className="headerLogin text-center m-5">Intake Webform Management System</h1>
            <div className='col-12 mt-5 carborderd auth_body bg-white'>
                <div className="">
                    <h3 className="mt-4 mr-3 headerh3">Login</h3>
                    {loading ? <LoadingOverlay /> : null}
                    <MessageModalMain textCentered ref={messageModalRef} />
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <label className="labelTextLogin">Username <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className={`form-control ${(usernameInvalid && !username) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                        marginBottom: '5px'
                                    }}
                                />
                                <div className="invalid-feedback">{usernameInvalid && !username ? usernameInvalid : ""}</div>
                            </div>
                        </div>
                        <div>
                            <label className="labelTextLogin">Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="password"
                                    className={`form-control ${passwordInvalid && !password ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <div className="invalid-feedback">{passwordInvalid && !password ? passwordInvalid : ""}</div>
                            </div>
                        </div>
                        <button
                            className="button_auth button_body mb-5"
                            style={{ marginTop: "32px" }}
                            onSubmit={() => handleSubmit()}
                        >
                            <p
                                style={{
                                    fontWeight: "800",
                                    fontSize: "16px",
                                    marginBottom: "0",
                                    color: "white",
                                }}
                            >
                                Log In
                            </p>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
