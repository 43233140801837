import React, { useState, useRef, useEffect } from "react";
import { CHECK_TOKEN_SENDEMIAL, RESET_PASSWORD } from "../api/urls";
import API from '../utils/request'
import '../styles/changePassword.css'
import { validatePassword } from "../utils/validation";
import MessageModalMain from "../containers/modal/messageModalMain";
import { Buffer } from 'buffer'
import LoadingOverlay from "../containers/loading/loadingOverlay";
import { useSearchParams } from 'react-router-dom';

const ChangePassword = () => {

    const [password, setPassword] = useState("")
    const [repassword, setRepassword] = useState("")
    const [isSubmit, setIsSubmit] = useState(false);
    const messageModalRef = useRef('');
    const [loading, setLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenPassword, setTokenPassword] = useState()

    useEffect(() => {
        if (searchParams.get('t')) {
            checkTokenSendEmail(searchParams.get('t'));
        }
        else {
            window.location.href = "/login";
        }
    }, [])

    const checkTokenSendEmail = (token) => {
        var param = {
            "pwdToken": token
        }
        API.post(CHECK_TOKEN_SENDEMIAL, param).then((res) => {
            if (res.data === false) {
                setTokenPassword(token)
            }
            else {
                messageModalRef.current.showWarning("Your access token has expired.", () => {
                    window.location.href = "/login";
                })
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleChangePassword = () => {
        setIsSubmit(true);
        if (!password || !repassword) {
            return false;
        } else if (password !== repassword) {
            return false;
        } else if (!validatePassword(password)) {
            return false;
        }
        var params = {
            "token": tokenPassword,
            "password": Buffer.from(password).toString('base64'),
        }
        setLoading(true)
        API.post(RESET_PASSWORD, params).then((res) => {
            setLoading(false);
            if (res.status === 1) {
                setPassword("")
                setRepassword("")
                messageModalRef.current.showSuccess(res.message, () => {
                    localStorage.clear();
                    window.location.href = "/login";
                })
                setIsSubmit(false);

            } else {
                messageModalRef.current.showWarning(res.message)
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message)
            setLoading(false)
        })

    }
    return (
        <div className="container" style={{ marginTop: '120px' }}>
            <h1 className="passwordH3">Reset Password</h1>
            <div className='col-12 mt-5 carborderd1 auth_body '>
                <div className="">
                    {loading ? <LoadingOverlay /> : null}
                    <MessageModalMain textCentered ref={messageModalRef} />
                    <div>
                        <div className="row mt-5">
                            <label className="labelTextLogin col-12 col-md-3 col-sm-12">New Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                <input
                                    type="password"
                                    className={`form-control newPassword ${isSubmit && (!password || (password && !validatePassword(password))) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {isSubmit && !password ? "The new password is required."
                                        : isSubmit && password && !validatePassword(password)
                                            ? "New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters."
                                            : ""
                                    }
                                </div>
                                {password && !validatePassword(password)
                                    ? null
                                    : <small className="" style={{ fontSize: "14px", color: '#949494', fontWeight: 400 }}>New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters</small>

                                }

                            </div>
                        </div>
                        <div className="row mt-5">
                            <label className="labelTextLogin col-12 col-md-3 col-sm-12">Confirm New Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                <input
                                    type="password"
                                    className={`form-control newPassword ${(isSubmit && !repassword) || (isSubmit && (password !== repassword)) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={repassword}
                                    onChange={(e) => {
                                        setRepassword(e.target.value);
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {
                                        isSubmit && !repassword ? "The confirm password is required." : isSubmit && (password !== repassword) ? "Password does not match." : "The confirm password is required."
                                    }
                                </div>
                            </div>
                        </div>
                        <button
                            className="button_auth button_bodymesssage"
                            style={{ marginTop: "32px" }}
                            onClick={() => handleChangePassword()}
                        >
                            <p
                                style={{
                                    fontWeight: "800",
                                    fontSize: "16px",
                                    marginBottom: "0",
                                    color: "white",
                                }}
                            >
                                Submit
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;