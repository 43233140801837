export const dataSubmission = [
  {
    name: "Before",
  },
  {
    name: "After",
  },
  {
    name: "Between",
  },
];

export const dataSearch = [
  {
    name: "Name",
  },
  {
    name: "Email",
  },
  {
    name: "Campaign Title",
  },
];

export const progressStatus = [
  {
    name: "Pending",
    value: "pending",
    isCheck: false,
  },
  {
    name: "In Review",
    value: "in-review",
    isCheck: false,
  },
  {
    name: "Approved",
    value: "approved"
  },
  {
    name: "In Progress",
    value: "in-progress",
    isCheck: false,
  },
  {
    name: "Campaign Live",
    value: "campaign-live",
    isCheck: false,
  },
  {
    name: "Completed",
    value: "completed",
    isCheck: false,
  },
  {
    name: "Archive",
    value: "archive",
    isCheck: false,
  },
  {
    name: "Request Denied",
    value: "request-denied",
    isCheck: false,
  }
];

export const bgCheck = (type) => {
  switch (type) {
    case "completed":
      return "#E1FFE6";
    case "campaign-live":
      return "#E1FFE6";
    case "in-progress":
      return "#FFF8D6";
    case "approved":
      return "#FFF8D6";
    case "archive":
      return "#D1F5F4";
    case "request-denied":
      return "#FFCCCB";
    default:
      return "#F1F1F1";
  }
};