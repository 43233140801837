import React, { useState } from 'react'
import CSLPlasma from '../assets/images/CSL_Logo.png'
import '../styles/mobileHeader.css'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineAccountCircle } from 'react-icons/md'
import { Link } from 'react-router-dom'

const MobileHeader = () => {

    const [active, setActive] = useState(false)
    const [isshowChild, setIsshowChild] = useState(null)

    const onClickActive = () => {
        setActive(!active)
        setIsshowChild(null)
    }

    if (active === true) {
        document.body.style.overflow = 'visable	';
    } else {
        document.body.style.overflow = 'unset';
    }

    const menus = [
        {
            target: "_blank",
            link: "/dashboard",
            title: "Dashboard",
        },
    ]

    return (
        <nav className='navbar sidebars navbar-expand-lg navbar-light text-dark bg-light d-lg-none d-flex'>
            <a href="#!" target='_blank' rel="noopener noreferrer" className='mx-5'>
                <img alt='' src={CSLPlasma} style={{ width: '100px' }} />
            </a>
            <button className="navbar-toggler"
                onClick={onClickActive}
                type="button" data-toggle="collapse"
                style={{
                    position: 'absolute',
                    right: '3rem',
                }}
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={
                active ?
                    'dropdown_menu' : 'dropdown_menu_down'
            }>
                <div className='body_navigation_menu'>
                    {
                        menus.map((m, index) => (
                            <>
                                <div key={index} className='d-flex flex-row align-items-center justify-content-between' style={{ cursor: 'pointer' }}>
                                    <a
                                        href={m.link}
                                        className='h-100' style={{ textDecoration: 'none', padding: '8px', color: 'black' }}>
                                        <p className={'navigation_text_menu'}>
                                            {m.title}
                                        </p>
                                    </a>
                                    {
                                        m.children && m.children.length > 0 &&
                                        <>
                                            {isshowChild === index + 1 ? <MdKeyboardArrowUp size={40} onClick={() => {
                                                if (isshowChild !== index + 1) {
                                                    setIsshowChild(index + 1)
                                                } else {
                                                    setIsshowChild(null)
                                                }
                                            }} />
                                                : <MdKeyboardArrowDown size={40} onClick={() => {
                                                    if (isshowChild !== index + 1) {
                                                        setIsshowChild(index + 1)
                                                    } else {
                                                        setIsshowChild(null)
                                                    }
                                                }} />}
                                        </>
                                    }
                                </div>
                                {isshowChild === index + 1 &&
                                    m.children &&
                                    <div style={{
                                        border: '1px solid rgba(0,0,0,0.15)',
                                        borderRadius: '0.25rem',
                                        padding: '1rem 0.5rem',
                                        marginBottom: '0',
                                    }}>
                                        {m.children.map((ch, Inch, index) => (
                                            <a
                                                href={ch.link}
                                                onClick={() => {
                                                    setIsshowChild(null)
                                                    onClickActive()
                                                }}
                                                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'black', cursor: 'pointer' }}
                                                key={ch.link}
                                            >
                                                {ch.title}
                                            </a>
                                        ))}
                                    </div>
                                }
                            </>
                        ))}
                    <div className='d-flex flex-column align-items-center' style={{ width: '100%' }}>
                        <div className='d-flex flex-row align-items-center' style={{ height: '59px' }}>
                            <MdOutlineAccountCircle size={24} style={{ marginRight: '8px' }} />
                            <div className="dropdown">
                                <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" style={{ fontSize: "14px", marginTop: "15px" }}>
                                    Admin User
                                    <p style={{ fontWeight: 'bold' }}>0001</p>
                                </button>
                                <RiArrowDropDownLine size={25} />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a href="/" className="dropdown-item">Log Out</a>
                                        <Link to="/change/password" className="dropdown-item">Change Password</Link>
                                        <a href="/manage" className="dropdown-item">Manage User</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default MobileHeader