import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import { storageKeys } from '../constants/storage'
import * as Storage from '../utils/storage'

const lang = Storage.getString(storageKeys.LANG) ? Storage.getString(storageKeys.LANG) : 'sp';

i18n.use(initReactI18next).init({
    fallbackLng: lang,
    lng: lang,
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        sp: {
            translations: require('./locales/sp/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
})

i18n.languages = ['en', 'sp']

export default i18n;