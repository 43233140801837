import React from 'react'
import Dashboard from './dashboard/dashboard'

const IndexPage = () => {
  return (
    <div className='col-md-12 container'>
      <Dashboard />
    </div>
  )
}

export default IndexPage